@charset "UTF-8";
@media (max-width: 1199.98px) {
  header .logo_col {
    flex-direction: column; }
  header .logo_col a {
    margin-bottom: 10px; }
  .main_screen .col_right {
    display: none; }
  .main_screen h1 {
    text-align: center; }
  .main_screen .calculator_btn {
    margin-left: auto;
    margin-right: auto; }
  .types_screed h2 {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .order_form form {
    padding-left: 30px;
    padding-right: 30px; }
  .order_form img {
    display: none; }
  .item_process_diagram:after {
    right: -23px; } }

@media (max-width: 991.98px) {
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -14px, 0) rotate(-270deg); }
  header .logo_col .header_slogan {
    display: none; }
  header .logo_col {
    flex-direction: row;
    justify-content: space-between; }
  header .phone_col {
    order: 1; }
    header .phone_col .header_phone_wrap {
      display: flex;
      justify-content: space-between; }
  header .menu_col {
    order: 2; }
  header .menu_col {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: -1000px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 100;
    transition: all 0.25s ease; }
    header .menu_col.show {
      left: 0; }
    header .menu_col nav {
      display: flex;
      align-items: center;
      position: absolute;
      height: 100vh;
      width: 260px;
      left: -15px;
      background-color: #fff; }
  header .menu_col ul {
    flex-direction: column;
    width: 100%; }
    header .menu_col ul li {
      margin-bottom: 15px;
      margin-right: 0;
      padding-left: 15px;
      padding-right: 15px;
      text-align: center; }
  header .menu_col a {
    color: #333;
    border-bottom: 1px solid rgba(51, 51, 51, 0.6); }
  .close_mobile_nav {
    display: block;
    position: absolute;
    right: 20px;
    top: 20px;
    color: #333;
    transition: all 0.25s ease;
    cursor: pointer; }
    .close_mobile_nav:hover {
      color: rgba(51, 51, 51, 0.5); }
  header .logo_col a {
    font-size: 13px; }
  header .logo_col a span {
    height: 28px;
    width: 33px;
    font-size: 17px; }
  .hamburger {
    position: relative;
    padding: 0;
    outline: none !important; }
    .hamburger:before {
      position: absolute;
      top: -5px;
      left: -50px;
      content: "Меню";
      font-family: "Exo2-SemiBold", "exo2-semibold", sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      color: #fff; }
  .hamburger-box {
    width: 22px; }
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    height: 3px;
    border-radius: 3px;
    width: 22px;
    background-color: #fff; }
  .hamburger--elastic .hamburger-inner::before {
    top: 7px; }
  .hamburger--elastic .hamburger-inner::after {
    top: 14px; }
  header .phone_col a {
    margin-top: 8px;
    font-size: 18px; }
  .main_screen h1 {
    margin-bottom: 51px;
    font-size: 26px;
    line-height: 36px; }
  .main_screen {
    padding-top: 161px;
    padding-bottom: 127px; }
  .why_we {
    padding-top: 74px;
    padding-bottom: 50px; }
  .why_we h2 {
    margin-bottom: 53px;
    font-size: 24px; }
  .why_item {
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px; }
  .why_desc {
    padding-right: 0;
    padding-top: 0;
    text-align: center; }
  .why_desc h3 {
    font-size: 20px;
    line-height: 30px; }
  .why_desc p {
    font-size: 14px;
    line-height: 24px; }
  .types_screed {
    padding-top: 70px;
    padding-bottom: 0; }
  .types_screed h2 {
    margin-bottom: 101px;
    font-size: 24px;
    line-height: 34px; }
  .screed_item {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 103px; }
  .why_screed {
    padding-top: 69px; }
  .why_screed h2 {
    margin-bottom: 70px;
    font-size: 24px;
    line-height: 34px; }
  .why_screed_col .why_screed_item {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 94px;
    text-align: center;
    background-position: top center !important; }
  .why_screed_item h4:after {
    position: absolute;
    left: 50%;
    margin-top: 29px;
    margin-left: -15px; }
  .why_screed_item h4 {
    font-size: 20px;
    margin-bottom: 63px;
    line-height: 30px; }
  .order_form h2 {
    max-width: 100%;
    margin-bottom: 35px;
    text-align: center;
    font-size: 24px;
    line-height: 34px; }
  .order_form .clock {
    max-width: 100%;
    text-align: center;
    background: none; }
  .order_form form {
    margin-left: auto;
    margin-right: auto;
    padding-top: 33px;
    padding-bottom: 76px;
    border-left: 0;
    border-right: 0; }
  .order_form .col_left {
    padding-top: 70px; }
  .differences {
    padding-top: 70px;
    padding-bottom: 70px; }
  .differences h2 {
    margin-bottom: 52px;
    font-size: 24px;
    line-height: 34px; }
  .application_area {
    padding-top: 69px;
    padding-bottom: 50px; }
  .application_area h2 {
    max-width: 100%;
    margin-bottom: 52px;
    font-size: 24px;
    line-height: 34px;
    text-align: center; }
  .application_area .item_application {
    margin-left: auto;
    margin-right: auto; }
  .application_area .item_application h4 {
    font-size: 20px; }
  .application_area .item_application img {
    margin-bottom: 24px; }
  .technology h2 {
    margin-bottom: 44px;
    font-size: 24px; }
  .item_technology {
    margin-bottom: 55px;
    margin-left: auto;
    margin-right: auto; }
  .item_technology .desc {
    padding-left: 20px;
    padding-right: 20px; }
  .technology {
    padding-top: 73px; }
  .item_technology .img_wrap {
    padding-left: 0; }
    .item_technology .img_wrap img {
      margin-left: auto;
      margin-right: auto; }
  .process_diagram h2 a {
    display: none; }
  .item_process_diagram {
    margin-left: auto;
    margin-right: auto; }
  .process_diagram h2 {
    margin-bottom: 73px;
    padding-right: 0; }
    .process_diagram h2 span {
      margin-left: auto;
      margin-right: auto;
      font-size: 24px; }
  .item_process_diagram:after {
    display: none; }
  .item_col {
    margin-bottom: 44px; }
  .item_process_diagram_last {
    margin-left: auto;
    margin-right: auto; }
  .composition h2 {
    font-size: 24px;
    text-align: center; }
  .item_composition {
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .item_composition .img_wrap img {
    margin-left: auto;
    margin-right: auto; }
  .composition h2 {
    margin-bottom: 62px; }
  .item_composition h4 {
    font-size: 20px;
    line-height: 30px; }
  .item_composition {
    margin-bottom: 0; }
  .composition .row:first-of-type .col-lg-4:nth-of-type(2),
  .composition .row:first-of-type .col-lg-4:nth-of-type(3) {
    margin-bottom: 60px; }
  .composition .row:last-of-type .col-lg-4 {
    margin-bottom: 60px; }
  .reviews {
    padding-top: 73px;
    padding-bottom: 45px; }
  .reviews h2 {
    margin-bottom: 55px;
    font-size: 24px; }
  .reviews .review .photo_wrap {
    float: none;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto; }
  .reviews .slider_reviews_wrap {
    border: none; }
  .reviews .review {
    margin-bottom: 30px;
    padding-bottom: 20px;
    border: 5px solid #f4f4f4;
    box-shadow: 10px 15px 45px 0 rgba(0, 0, 0, 0.03); }
  .reviews .review p {
    font-size: 14px;
    line-height: 24px;
    text-align: center; }
  .reviews .review .desc:after {
    left: 0; }
  .reviews .col_right {
    display: none; }
  .operating_procedure {
    padding-top: 69px;
    padding-bottom: 20px; }
  .operating_procedure h2 {
    margin-bottom: 50px;
    font-size: 24px;
    line-height: 36px;
    text-align: center; }
  .operating_procedure .item_operating {
    margin-bottom: 53px;
    text-align: center; }
  .operating_procedure .num_operating {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 22px; }
  .operating_procedure h4 {
    font-size: 20px;
    line-height: 30px; }
  .video_photo h2 {
    margin-bottom: 52px;
    font-size: 24px;
    line-height: 34px; }
  .video_photo {
    padding-top: 71px;
    padding-bottom: 80px; }
  .video_photo .video_wrap {
    margin-left: auto;
    margin-right: auto; }
  .coast {
    padding-top: 69px;
    padding-bottom: 80px; }
  .coast h2 {
    margin-bottom: 90px;
    font-size: 24px;
    line-height: 36px;
    text-align: center; }
  .coast .table {
    display: none; }
  .coast .mobile_table {
    display: block;
    margin-bottom: 60px; }
    .coast .mobile_table .table_header .table_col {
      width: 50%; }
  .coast .mobile_table .table_body .table_row .table_col {
    width: 50%; }
  .stock {
    padding-top: 69px;
    padding-bottom: 70px; }
  .stock .col_left h2 {
    font-size: 24px;
    line-height: 36px;
    text-align: center; }
  .stock .col_left h4 {
    margin-bottom: 40px;
    font-size: 20px;
    line-height: 30px;
    text-align: center; }
  .stock .stock_clock {
    padding-left: 0;
    text-align: center;
    background: none; }
  .stock #timerb74d8f6629d1c4409cef3b4723cab625 {
    margin-bottom: 18px;
    text-align: center !important; }
  .stock form {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto; }
  .stock .col_conf {
    margin-left: 0; }
  .stock #timer-text-b74d8f6629d1c4409cef3b4723cab625-hours, .stock #timer-text-b74d8f6629d1c4409cef3b4723cab625-minutes, .stock #timer-text-b74d8f6629d1c4409cef3b4723cab625-secundes {
    text-align: left !important; }
  footer {
    padding-top: 32px;
    padding-bottom: 20px; }
  .top_footer .col_left a {
    font-size: 14px; }
  .top_footer .col_left a img {
    height: 30px;
    margin-left: 6px; }
  .top_footer .col_left {
    display: flex;
    justify-content: space-between; }
  .top_footer ul {
    flex-wrap: wrap;
    justify-content: center; }
  .top_footer ul li {
    margin-bottom: 15px; }
  .top_footer .footer_phone_wrap .blue_btn {
    display: none; }
  .top_footer .footer_phone_wrap {
    display: flex;
    justify-content: space-between;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto; }
  .top_footer .footer_tel {
    font-size: 18px; }
  .top_footer .col_center {
    order: 2; }
  .top_footer .col_right {
    order: 1; }
  .bottom_footer {
    padding-top: 10px;
    padding-bottom: 10px; }
  .differences .mobile_table {
    margin-bottom: 60px; }
    .differences .mobile_table .table_header .table_col {
      width: 50%; }
  .differences .mobile_table .table_body .table_row .table_col {
    width: 50%; }
  .privacy h2 {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 36px; }
  .privacy h3 {
    font-size: 22px; }
  .main_screen.thanks_block h1 {
    margin-bottom: 20px; }
  .main_screen.thanks_block h5 {
    font-size: 18px; } }

@media (max-width: 767.98px) {
  .differences {
    padding-bottom: 40px; }
  .differences .mobile_table {
    display: block; }
  .differences .table {
    display: none; }
  .top_footer .col_left {
    margin-bottom: 15px; }
  .bottom_footer > .container {
    flex-direction: column;
    align-items: center; }
  .differences .mobile_table .table_body .table_row .table_col {
    text-align: center;
    padding-left: 10px;
    padding-right: 10px; } }

@media (max-width: 575.98px) {
  .order_form form {
    padding-left: 0;
    padding-right: 0; }
  .stock #timerb74d8f6629d1c4409cef3b4723cab625 {
    max-width: 320px !important;
    min-width: auto !important;
    margin-left: auto !important;
    margin-right: auto !important; } }
